import React from 'react';
import { connect } from 'react-redux';
import { handleModal } from './modal';
import { 
  ActionProductsListing, 
  ActionProductsTesting, 
  ActionHomePageBooks, 
  ActionStory 
} from  './productActions';

class ProductAction extends React.Component {

  addVariantToCart = (variantId, quantity) => {
    const lineItem = [{ variantId, quantity: parseInt(quantity, 10) }];
    this.props.localAddVariantToCart({ references: lineItem });
  }

  handlePrintable = (story) => {
    this.handleModal(story);
  }

  handleModal = (story) => {
    this.props.handleModal({
      modal: !this.props.modal,
      story: story
    });
  }

  render() {
    const ProductsActionButtons = (props) => {
      if (this.props.page === 'story') {
        return (
          <ActionStory {...props} />
        );
      } else if (this.props.page === 'index') {
        return (
          <ActionHomePageBooks {...props} />
        );
      } else if (this.props.page === 'listing') {
        return (
          <ActionProductsListing {...props} />
        );
      } else if (this.props.page === 'testing') {
        return (
          <ActionProductsTesting {...props} />
        );
      } else if (this.props.page === 'listing-unset') {
        return (
          <ActionProductsListing {...props} unset={true} />
        );
      }
    }
    
    return (
      <div>
        <ProductsActionButtons 
          handlePrintable={this.handlePrintable} 
          product={{ 
            ...this.props.product,
            id: this.props.product.id, 
            projectId: this.props.product.projectId, 
            name: this.props.product.name,
            type: this.props.product.type,
            isPrintable: this.props.product.isPrintable,
            isBook: this.props.product.isBook,
            inAppPurchase: this.props.product.inAppPurchase,
            popupBackground: this.props.product.popupBackground
          }}
          lang={this.props.context.langKey}
          disabled={this.props.context.langKey === 'en'}
          _t={this.props._t}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.modal
});

const mapDispatchToProps = (dispatch) => ({
  handleModal: (state) => dispatch(handleModal(state))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductAction);